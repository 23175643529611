.mailing-list {
    clear: both;
    background: $hdsDarkGreen;
    text-align: center;
    border-radius: 2px 2px 0 0;
    padding: 30px 30px 0;
    margin: 75px auto 0;
    @include grid-container;

    .mailing {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
    }

    h5 {
        margin: 15px 0 0;
        font: 900 24px/1.1em $lato;
        color: #fff;
    }

    p {
        font-weight: 300;
        margin: 10px auto 0;
        color: #fff;
    }

    form {
        max-width: 600px;
        margin: 10px auto;

        .content {
            background: #fff;
            border-radius: 4px;
            padding: 5px 8px;
            margin: 15px 0 20px;
            width: 100%;
            @include clearfix;

            input[type="email"] {
                font-size: 16px;
                appearance: none;
                padding: 8px 10px 4px;
                border: 1px solid #ccc;
                margin-bottom: 7px;
                width: 100%;
                border-radius: 2px;
                width: 100%;
                @include grid-media($fourFifty) {
                    border: none;
                    float: left;
                    width: auto;
                    margin-bottom: 0;
                }
            }

            input[type="submit"] {
                font-size: 14px;
                text-align: center;
                border-radius: 2px;
                background: $hdsGreen;
                appearance: none;
                padding: 8px 45px;
                width: 100%;
                color: #fff;
                cursor: pointer;
                border: none;
                @include grid-media($fourFifty) {
                    width: auto;
                    float: right;
                }

                &:hover {
                    background: shade($hdsGreen, 10%);
                }
            }
        }
    }

    &.bottom {
        border-radius: 0 0 2px 2px;
        background: $darkBlue;
        margin: 0 auto;
        position: relative;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 400%;
            background: #fff;
        }

        &:before {
            left: -400%;
        }

        &:after {
            right: -400%;
        }

        p {
            margin-bottom: 25px;
        }

        a {
            color: #fff;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

#tagline {
    color: $hdsDarkGreen;
    letter-spacing: -0.19px;
    font-size: 19px;
    font-weight: 300;
    margin-top: 15px;
}

.space {
    margin-bottom: 30px;
}

#colophon {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    @include grid-media($sixFifty) {
        padding-bottom: 50px;
    }
    @include grid-media($oneK) {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &:after {
        content: '';
        height: 0;
        left: 0;
        padding-top: 12.2%;
        position: absolute;
        width: 100%;
        z-index: 2;
        background-image: linear-gradient(-7deg,#032C5B 0,#032C5B 50%,transparent 50.1%,transparent 100%);
        bottom: 0;
    }

    .wrap {
        position: relative;
        z-index: 8;
    }

    .footlogo {
        max-height: 56px;
        max-width: 250px;
        display: block;
        margin: 12px auto 20px;
        @include grid-media($sixFifty) {
            @include grid-column(6);
            max-width: 400px;
        }
        @include grid-media($eightHundo) {
            margin-bottom: 120px;
        }
    }

    p {
        text-align: center;
        font-size: 11px;
        margin-top: 30px;
        margin-bottom: 30px;
        @include grid-media($sixFifty) {
            text-align: left;
            margin: 30px 0 0 3px;
        }
        @include grid-media($oneK) {
            margin-bottom: 0;
        }
    }

    .footer-navigation.first {
        @include grid-media($sixFifty) {
            @include grid-column(5);
            float: right;
        }
        @include grid-media($oneK) {
            width: calc(100% - 560px);

            > div {
                width: 50%;
                float: left;
            }
        }
    }

    .menu {
        li {
            margin: 5px auto;

            a {
                text-align: center;
            }
            @include grid-media($sixFifty) {
                margin: 6px 0;

                a {
                    text-align: left;
                }
            }
            @include grid-media($oneK) {
                margin-right: 0;
            }

            &.button {
                display: none;
                visibility: hidden;
                margin: 0;
                height: 0;
            }
        }

        &#credit-menu {
            li {
                @include grid-media($sixFifty) {
                    display: inline-block;
                }
            }
        }
    }

    .widget {
        margin-bottom: 20px;
    }
    @include grid-media($fiveHundo) {
        text-align: left;

        #tagline,
        .logo,
        .widget {
            float: left;
            margin-right: 10px;
        }
    }

    .logo {
        display: inline-block;
        @include grid-media($fourFifty) {
            //@include grid-column()
        }

        svg {
            max-width: 81px;
            max-height: 56px;
            @include grid-media($fiveHundo) {
                max-height: 67px;
            }
        }
    }
    @include grid-media($fiveFifty) {
        .menu-main-container {
            left: 0;
        }
    }
    @include grid-media($sixFifty) {
        .space {
            @include grid-column(3);
            max-width: 140px;
        }

        .widget {
            margin-right: 40px;
        }
    }

    .widget {
        letter-spacing: -0.17px;
    }

    .widget-title {
        font-size: 16px;
        line-height: 22px;
        color: #888;
        font-weight: 400;
        margin: 0;
    }

    .textwidget {
        p {
            color: #888;
            font-size: 13px;
            line-height: 22px;
            margin: 0;
        }

        a {
            color: $hdsBlue;
        }
    }

    .menu-main-container,
    .menu-second-menu-container {
        .menu {
            li {
                display: block;
                text-align: left;
            }
        }
    }

    .menu-second-menu-container {
        @include grid-media($sixFifty) {
            position: relative;
            top: -15px;
        }
        @include grid-media($oneK) {
            top: 0;
        }
    }
}

#second-menu {
    @include grid-media($sixFifty) {
        margin-top: -15px;
    }
    @include grid-media($oneK) {
        margin-top: 0;
    }
}

.home {
    #colophon {
        margin-top: 60px;
        padding-top: 0;
    }
}