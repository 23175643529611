#fixed-header {
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 34;
    min-height: 88px;
    border-bottom: 1px solid #e2e2e2;
}

#masthead {
    padding-top: 66px;
    @include grid-media($eightFifty) {
        padding-top: 109px;
    }
}

.admin-bar {
    #fixed-header {
        top: 32px;
    }

    #masthead {
        padding-top: 44px;
        @include grid-media($eightFifty) {
            padding-top: 110px;
        }
    }
}

#site-branding {
    float: left;
    max-width: 60%;
    position: relative;
    margin-top: 20px;
    @include grid-media($sevenFifty) {
        width: 30%;
    }
    @include grid-media($eightFifty) {
        margin-top: 0;
        max-width: 140px;
    }
}

.logo {
    max-width: 250px;
    width: 100%;
    max-height: 50px;
    @include grid-media($eightFifty) {
        max-height: 66px;
    }

    &.in-page {
        //max-width: 458px;
        //width: 90%;
        max-width: 327px;
        margin: 0 0 10px;
        max-height: 75px;
        @include grid-media($fourFifty) {}
        @include grid-media($sixHundo) {
            max-height: 100px;
        }
        @include grid-media($tablet) {
            max-width: 425px;
        }
        @include grid-media($eightHundo) {
            margin-bottom: 30px;
        }
    }
}

#site-eyebrow {
    text-align: center;
    visibility: hidden;
    display: none;
    @include grid-media($eightFifty) {
        visibility: visible;
        display: block;
    }

    a:not(.button) {
        font-size: 14px;
        display: inline-block;
        margin-top: 16px;
        color: $base;

        &:hover {
            color: $hdsBlue;
        }
    }

    .button {
        display: inline-block;
        margin: 5px 0;
        min-width: 203px;
        padding: 6px 17px;

        &:first-of-type {
            display: none;
        }
        @include grid-media($fiveHundo) {
            &:first-of-type {
                display: inline-block;
                margin-left: 1%;
            }
            margin: 8px 0;
        }
        @include grid-media($fiveFifty) {
            min-width: 210px;
            margin: 8px 0 8px 10px;
        }
        @include grid-media($eightFifty) {
            margin-top: 15px;
            padding: 10px 17px;
        }
    }
    @include grid-media($eightFifty) {
        padding: 0;
        background: transparent;
        margin-bottom: 0;
    }
}

#masthead {
    //padding: 15px 0 0;
    overflow: hidden;

    .logo {
        width: 60%;
        max-width: 200px;
        float: left;
    }
}

.home {
    #masthead {
        //padding: 15px 0 5px;
    }
}

.menu {
    list-style: none;
    padding-left: 0;
    margin: 0;

    a {
        display: block;
        color: $base;
        padding: 3px;
        @include grid-media($eightFifty) {
            padding: 0;
        }

        &:hover {
            color: $hdsGreen;
        }
    }

    li {
        display: block;
        padding: 3px;
        font-size: 15px;
        line-height: 16px;

        &.current-menu-item {
            a {
                color: $hdsBlue;
            }
        }
        @include grid-media($eightFifty) {
            &.hide-desktop {
                display: none !important;
                visibility: hidden;
                margin-left: 0 !important;
            }
        }

        &.button {
            padding: 0 8px;
            float: none;
            border: none;
            margin: 7px 0 21px;
            @media all and (min-width:425px) {
                display: inline-block;
                width: 49%;
                //&:first-of-type {
                padding-left: 1%;
                padding-right: 0;
                //}
            }

            &:hover {
                background: transparent;
            }

            a {
                text-transform: none;
                display: inline-block;
                border-radius: 3px;
                padding: 6px 17px;
                text-align: center;
                font-size: 14px;
                width: 90%;
                @media all and (min-width:400px) {
                    width: calc(100% - 15px);
                }
            }

            &.hdsBlueBorder {
                a {
                    border: 2px solid $hdsBlue;
                    color: $hdsBlue;

                    &:hover {
                        background: $hdsBlue;
                        color: #fff;
                    }
                }
            }

            &.abBlueBorder {
                a {
                    border: 2px solid $abBlue;
                    color: $abBlue;

                    &:hover {
                        background: $abBlue;
                        color: #fff;
                    }
                }
            }
            @include grid-media($eightFifty) {
                display: none !important;
                visibility: hidden;
            }
        }
    }
}

#masthead {
    .menu {
        li {
            text-align: center;
            border-bottom: 1px solid #efefef;
            margin: 15px auto;
            padding-bottom: 15px;
            @include grid-media($eightFifty) {
                display: inline-block;
                text-align: right;
                margin: 0 0 0 10px;
                padding-bottom: 0;
                border: none;
                padding: 0;

                &:nth-last-child(3),
                &:nth-last-child(4) {
                    margin-right: 0 !important;
                }
            }
            @include grid-media($oneK) {
                font-size: 16px;
                margin-right: 25px;
            }

            a:not(.button) {
                &:hover {
                    color: $hdsBlue;
                }
            }
        }
    }
}

#site-navigation {
    float: right;
    margin-top: 19px;

    .menu-main-container {
        position: absolute;
        left: 0;
        top: 77px;
        margin-left: -20px;
        width: calc(100% + 40px);
        background: #fff;
        z-index: 90;
        text-align: center;
        @include clearfix;

        &:after,
        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 80px;
            top: 0;
            background: #fff;
        }

        &:before {
            left: -80px;
        }

        &:after {
            right: -80px;
        }
        @include grid-media($fiveFifty) {
            left: -11px;
            width: calc(100% + 63px);
        }
        @include grid-media($sevenFifty) {
            top: 74px;
        }
        @include grid-media($eightFifty) {
            top: 4px;
            background: transparent;
            text-align: right;
            left: 0;
            width: 100%;
            position: relative;
            float: right;

            &:after,
            &:before {
                content: none;
            }

            .menu {
                li {
                    display: inline-block;
                    margin-left: 15px;
                }
            }
        }
        @include grid-media($oneK) {
            top: 0;

            .menu {
                li {
                    font-size: 18px;
                }
            }
        }
    }
    @include grid-media($eightFifty) {
        width: calc(100% - 140px);
        float: right;
        margin-top: 35px;
        position: relative;
    }
    @include grid-media($oneK) {
        margin-top: 37px;
    }
}

.navigation-top {
    clear: both;
    @include grid-media($eightFifty) {
        position: relative;
        top: -20px;
        margin-bottom: -10px;
    }
}

.menu-toggle {
    position: relative;
    border: none;
    appearance: none;
    z-index: 7;
    width: 32px;
    height: 38px;
    overflow: hidden;
    display: block;
    text-indent: -400px;
    cursor: pointer;
    top: 9px;
    background: transparent;
    @media all and (min-width:350px) {
        top: 11px;
    }
    @media all and (min-width:400px) {
        top: 16px;
    }
    @media all and (min-width:500px) {
        top: 19px;
    }

    span,
    span:after,
    span:before {
        display: block;
        height: 3px;
        width: 30px;
        background: $hdsBlue;
        position: absolute;
        left: 0;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }

    span {
        transform: translateX(0) translateY(-50%);
    }

    span:after,
    span:before {
        content: '';
    }

    span:before {
        transform: translateY(-8px);
    }

    span:after {
        transform: translateY(8px);
    }

    &:hover {
        span,
        span:after,
        span:before {
            background: rgba($hdsBlue, 0.7);
        }

        span:before {
            left: 6px;
        }

        span {
            left: 4px;
        }

        span:after {
            left: -2px;
        }
    }
}

#top-menu {
    //display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    box-shadow: 0 3px 4px #a7a7a7;
    @include grid-media($eightFifty) {
        max-height: none;
        box-shadow: none;
    }
}

.nav-open {
    .menu-toggle {
        span {
            background: transparent;
        }

        span:before {
            transform: rotate(45deg);
            left: 0;
        }

        span:after {
            transform: rotate(-45deg);
            left: 0;
        }
    }

    #top-menu {
        max-height: 2000px;
    }
}
@include grid-media($eightFifty) {
    .menu-main-container {
        position: relative;
        width: auto;
    }

    #top-menu {
        display: block;
    }

    .menu-toggle {
        display: none;
        visibility: hidden;
    }
}