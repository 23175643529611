// FONTS
$regular: 400;
$semi: 500;
$bold: 700;
$heavy: 900;
$lato: 'lato', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
$rockwell: "rockwell", sans; // COLORS
$base: #4E4E56;
$lightGray: #E4E4E5;
$midGray: #888888;
$hdsBlue: #3085DA;
$abBlue: #00509E;
$hdLightGreen: rgba(0,97,53,.56);
$absLightBlue: #00539B;
$hdsGreen: #95BF4E;
$hdsDarkGreen: #006135;
$lightBg: #F4F4F4;
$gray: #9B9B9B;
$darkBlue: #032C5B; // SIZES
$fourHundo: (
    columns: 12,
    gutter: 20px,
    media: 400px
);
$fourFifty: (
    columns: 12,
    gutter: 20px,
    media: 450px
);
$fiveHundo: (
    columns: 12,
    gutter: 20px,
    media: 500px
);
$fiveFifty: (
    columns: 12,
    gutter: 20px,
    media: 550px
);
$sixHundo: (
    columns: 12,
    gutter: 20px,
    media: 600px
);
$sixFifty: (
    columns: 12,
    gutter: 20px,
    media: 650px
);
$tablet: (
    columns: 12,
    gutter: 20px,
    media: 678px
);
$sevenFifty: (
    columns: 12,
    gutter: 20px,
    media: 750px
);
$eightHundo: (
    columns: 12,
    gutter: 20px,
    media: 800px
);
$eightFifty: (
    columns: 12,
    gutter: 20px,
    media: 850px
);
$oneK: (
    columns: 12,
    gutter: 20px,
    media: 1000px
);
$wrap: (
    columns: 12,
    gutter: 20px,
    media: 1200px
)