.preamble {
    p {
        font: 300 28px/32px $lato;
        letter-spacing: -0.2px;
        color: $abBlue;
    }
}

.repeater-block {
    display: flex;
    text-align: center;
    margin: 15px 0 20px;

    &.4 {}

    h6 {
        font-size: 19px;
        line-height: 24px;
        letter-spacing: -0.11px;
        font-weight: 900;
        margin: 0;
    }

    img {
        border-radius: 50%;
        max-width: 173px;
    }

    p {
        margin: 3px 0;
    }

    .arrow {
        fill: $abBlue;
        margin: 80px 0 0;
        width: 18px;
    }
}

.values {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    margin-bottom: 50px;
    margin-top: 20px;

    b,
    strong {
        color: $abBlue;
    }
    @include grid-media($fiveFifty) {
        max-width: none;
        text-align: left;
        @include grid-column(6);
        margin-bottom: 30px;

        &:nth-of-type(2n+1) {
            margin-left: 0;
        }
    }

    h4 {
        font-size: 19px;
        margin-bottom: 0;
    }

    p {
        line-height: 1.5em;
        font-size: 14px;
        letter-spacing: -0.09px;
        @include grid-media($fiveFifty) {
            width: calc(100% - 30px);
        }
        @include grid-media($sixFifty) {
            width: 100%;
        }
        @include grid-media($tablet) {
            clear: left;
            position: relative;
            top: 10px;
        }
        @include grid-media($eightFifty) {
            width: calc(100% - 145px);
            clear: none;
            float: left;
        }
        @include grid-media($wrap) {
            width: calc(100% - 165px);
        }
    }

    img {
        max-width: 100%;
        max-width: 115px;
        border-radius: 50%;
        background: #fff;
        @include grid-media($tablet) {
            float: left;
            margin-right: 15px;
        }
    }
}

.home {
    .values {
        @include grid-media($fiveFifty) {
            margin-left: 20px;

            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }
}

.faq-block {
    @include grid-collapse;

    .block {
        border: 1px solid $lightBg;
        padding: 25px;
        margin: 15px 0;

        .a,
        .question {
            font-size: 19px;
            line-height: 24px;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        .a,
        .q {
            display: inline-block;
            margin-right: 5px;
        }

        .a,
        .answer,
        .q {
            vertical-align: top;
        }

        .a {
            margin-top: 9px;
        }

        .answer {
            display: inline-block;

            > div {
                width: calc(100% - 25px);
                display: inline-block;
            }
        }
    }
}
// FAQ

.page-id-29 {
    article.page {
        @extend .wrap;
    }
    @include grid-media($eightFifty) {
        .entry-header {
            @include grid-column(4);
            margin-left: 0;
        }

        .entry-content {
            @include grid-column(8);
            float: right;
        }
    }
}

.single {
    .entry-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.2em;
        }

        h2 {
            font-size: 26px;
        }

        h3 {
            font-size: 22px;
        }

        .preamble {
            em {
                font-size: 24px;
                line-height: 1.3em;
            }
        }

        .smaller {
            em,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                font-size: 94%;
                line-height: 1.3em;
            }
        }

        .footnote {
            padding: 15px 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            font-size: 93%;
        }
    }
}