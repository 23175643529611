.news-excerpts {
    margin: 65px 0 0;

    &.columned {
        article {
            @include grid-media($fiveFifty) {
                @include grid-column(6);

                &:nth-of-type(odd) {
                    clear: left;
                }
            }
        }
    }

    article {
        background: #fff;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 20px;

        &:last-of-type {
            padding-bottom: 30px;
        }

        h3 {
            font-size: 19px;
            line-height: 23px;
            margin: 10px 0 0;
            letter-spacing: 0.5px;

            a {
                color: $hdsBlue;

                &:hover {
                    color: $hdsGreen;
                }
            }
        }

        p {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.1px;
            color: #555555;
        }
    }
    @include grid-media($sevenFifty) {
        .content-holder {
            //@include grid-column(9.25);
            position: relative;
            top: -10px;
        }

        .post-thumbnail {
            width: 80px;
            margin-right: 15px;
        }

        .content,
        .post-thumbnail {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.single {
    #primary {
        margin-top: 25px;
    }
    @include grid-media($sevenFifty) {
        #primary,
        #secondary {
            margin-top: 40px;
        }
    }

    .entry-title {
        font-size: 27px;
        line-height: 1.1em;
        font-weight: 900;
        letter-spacing: -.34px;
        margin: 0 0 10px;
        @include grid-media($sevenFifty) {
            font-size: 36px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $absLightBlue;
        text-align: left;
    }

    #main,
    #secondary {
        @include grid-column(12);
    }

    #secondary {
        margin-bottom: 85px;
    }
    @include grid-media($sevenFifty) {
        #main {
            @include grid-column(8);
        }

        #secondary {
            @include grid-column(3.5);
            @include grid-shift(0.5);
        }
    }

    .entry-content {
        em,
        li,
        p {
            font-size: 17px;
            line-height: 23px;
        }

        p {
            margin-bottom: 25px;
        }

        sup {
            font-size: 0.7em;
            line-height: 1em;
        }

        blockquote {
            border-top: 4px solid $abBlue;
            border-bottom: 4px solid $abBlue;
            margin: 40px 0;
            width: 83% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            p {
                font-size: 26px;
                opacity: 0.8;
                line-height: 1.4em;
            }
        }
    }

    blockquote {
        &:not(.featured) {
            width: 100%;
            float: none;
            margin-right: 0;
            padding: 22px 40px;
            font-size: 18px;
            line-height: 25px;
        }
    }
}

#secondary {
    .widget-title {
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
    }
}

.entry-meta {
    text-transform: uppercase;
}

header {
    .entry-meta {
        color: $gray;
        font-size: 12px;
    }
}

.archive {
    .block-it.title {
        margin-top: 27px;
        @include grid-media($tablet) {
            margin-top: 77px;
        }
    }

    .news-excerpts {
        margin-top: 25px;
        margin-bottom: -50px;
        @include grid-media($sixFifty) {
            margin-top: 65px;
        }
    }

    article.news {
        margin-bottom: 20px;
    }

    .entry-title {
        margin: 10px 0;
        font-size: 19px;
    }
}

.page:not(.home) {
    .news-excerpts {
        margin: 50px 0 -50px;
    }
}