.block {
    &-blue,
    &-gray {
        overflow: hidden;
    }

    &-blue {
        background: $hdsBlue;

        cite,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        li,
        p {
            color: #fff;
        }

        hr {
            background: rgba(#fff, 0.5);
        }
    }

    &-gray {
        background: $lightBg;
        padding-bottom: 30px;

        blockquote {
            @include grid-media($eightFifty) {
                margin-top: 37px;
            }
            @include grid-media($oneK) {
                margin-top: 94px;
                padding-top: 0;
            }

            p {}
        }

        hr {
            background: rgba(#fff, 0.5);
        }
    }
}

.diagonal {
    position: relative;

    &.top {
        padding-bottom: 60px;
        //margin-top: 30px;
        padding-top: 60px;
        @include grid-media($eightFifty) {
            padding-top: 115px;
        }
    }

    &.bottom {
        padding-bottom: 115px;
        margin-top: 50px;
    }
}