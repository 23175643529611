#preamble {
    text-align: center;

    .bigger {
        max-width: 990px;
        margin: 0 auto;
    }
}

#value-add {
    margin-top: 50px;
    @include clearfix;
    padding: 50px 0 0;
    @include grid-media($tablet) {
        padding: 80px 0 30px;
    }
}

.big-strong {
    p {
        b,
        strong {
            margin-bottom: 0;
            line-height: 1em;
            font-size: 19px;
            color: $abBlue;
        }
    }
}

.copy-blockquote p,
blockquote,
blockquote em,
blockquote p {
    font: 300 22px/29px $rockwell;
    color: #0a529c;
    letter-spacing: -0.2px;
    @include grid-media($tablet) {
        font-size: 30px;
        line-height: 36px;
    }

    cite {
        display: block;
        margin: 30px 0;
        font-family: 'lato';
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $midGray;

        span {
            display: block;
        }
    }
}

.copy-blockquote p {
    font-size: 26px;
    text-align: center;
    @include grid-media($sixFifty) {
        text-align: left;
    }
    @include grid-media($tablet) {
        font-size: 30px;
    }
}

.center.left-six50 {
    @include grid-media($sixFifty) {
        text-align: left;
    }
}

blockquote p {
    display: inline;
}

blockquote.featured {
    text-align: center;
    max-width: 900px;
    margin: 0 auto 90px;
    padding-top: 100px;

    .quote {
        font-size: 32px;
    }

    cite span {
        display: inline;
    }

    p {
        font-size: 28px;
        line-height: 40px;
        @include grid-media($tablet) {
            font-size: 32px;
            line-height: 44px;
        }
    }
}

.diagonal {
    blockquote.featured {
        padding-top: 35px;
        margin-bottom: 30px;
    }
}

.home {
    blockquote.featured {
        padding-top: 0;
    }
}

blockquote {
    margin: 10px 0;
    padding-top: 27px;
    /*
    &:not(.featured) {
        @include grid-media($sixFifty) {
            @include grid-column(6);

            &:nth-child(odd) {
                margin-left: 0;
                margin-right: 40px;
            }
        }
    }
    */
}

#testimonials {
    h3 {
        margin: 20px 0;
    }

    blockquote:not(.featured) {
        margin-bottom: 40px;
    }
}