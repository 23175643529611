html {
    letter-spacing: -0.3px;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: $base;
}

* {
    margin: 0;

    &:focus {
        outline: none;
    }
}

body,
html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: 100vh;
}

#page {
    height: 100vh;
}

.site-content-contain {
    background: #fff;
    z-index: 2;
    position: relative;
    min-height: calc(100% - 75px);
    padding-bottom: 500px;
    @include grid-media($fiveHundo) {
        padding-bottom: 525px;
    }
    @include grid-media($sixFifty) {
        padding-bottom: 400px;
    }
    @include grid-media($eightFifty) {
        padding-bottom: 399px;
    }
    @include grid-media($oneK) {
        padding-bottom: 413px;
    }
}

.entry-content {
    #improve-p-spacing {
        p {
            padding-bottom: 12px;
        }
    }
}

#colophon {
    position: absolute;
    bottom: 0;
    width: 100%;
    //z-index: 5;
    /*bottom: -338px;
    @include grid-media($fiveHundo) {
        bottom: -242px;
    }
    @include grid-media($oneK) {
        bottom: -312px;
    }*/
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

a,
button,
input[type="submit"] {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

a {
    color: $hdsBlue;

    &:hover {
        //color: shade($abBlue, 10%);
        color: $hdsGreen;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
html,
li,
p {
    font-family: $lato;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.5px;
    color: $abBlue;
    text-align: center;

    &.white {
        color: #fff;
    }
}

h2,
h3 {
    font-weight: 900;
    font-size: 26px;
    line-height: 1.2em;
    @include grid-media($sixHundo) {
        font-size: 32px;
    }
}

h2 {
    line-height: 1em;
}

h3 {
    text-align: left;
}

h4 {
    font-size: 19px;
    letter-spacing: 0;

    &.bigger-margin {
        margin-top: 50px;
    }
}

header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: left;
    }

    &.entry-header {
        h1 {
            color: $base;
            line-height: 1.1em;
            font-size: 27px;
            @include grid-media($fiveFifty) {
                font-size: 32px;
            }
        }
    }
}

li,
p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin: 10px 0;
}

.hds {
    white-space: nowrap;
}

.bigger {
    p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;
        @include grid-media($tablet) {
            font-size: 22px;
            line-height: 34px;
        }
        @include grid-media($oneK) {
            font-size: 24px;
            line-height: 36px;
        }
    }
}
// GENERAL

.page {
    #primary > #main > article > .entry-content > section:first-of-type {
        padding-top: 20px;
    }
}

.bump-topBottom {
    margin: 30px auto 15px;
}

.bump-top {
    margin-top: 15px;
}

.bump-extraTop {
    margin-top: 45px;
}

section {
    &.bump-top {
        margin-top: 45px;
    }
}

.bump-topSides {
    margin-top: 45px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @include grid-media($tablet) {
        max-width: 83%;
    }
}

.bump-bottom {
    margin-bottom: 15px;
}

.bump-bottomSides {
    margin-bottom: 15px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @include grid-media($tablet) {
        max-width: 83%;
    }
}

.bump-all {
    margin: 45px auto 15;
    max-width: 90%;
}

.bump-sides {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @include grid-media($tablet) {
        max-width: 83%;
    }

    .bump-sides {
        max-width: 100%;

        .copy-blockquote p {
            text-align: center;
        }

        p.floater {
            text-align: center;
            margin-top: 40px;
        }
        @include grid-media($tablet) {
            max-width: 745px;

            .big-strong {
                width: calc(100% - 280px);
            }

            .big-strong,
            p.floater {
                float: left;
            }

            .copy-blockquote p,
            div.center,
            p.floater {
                text-align: left;
            }

            p.floater {
                padding-top: 38px;
                padding-left: 40px;
            }
        }
        @media all and (min-width:800px) {
            p.floater {
                padding-top: 8px;
                padding-left: 50px;
                //margin-top: 0;
            }

            .big-strong {
                width: calc(100% - 290px);
            }
        }
    }
}

hr {
    width: 100%;
    height: 1px;
    margin: 25px auto;
    border-style: none;
    background-color: rgba($base, 0.5);
}

.wrap {
    position: relative;
    margin: 0 auto;
    clear: both;
    width: 92%;
    max-width: 1200px;
    @include clearfix;
}

.block-it {
    &.title {
        margin-bottom: 30px;
        margin-left: 16px;
    }
    @include grid-media($sixFifty) {
        @include grid-column(9.25);

        &.title {
            margin-bottom: 15px;
            @include grid-column(2.75);
            text-align: left;
        }
    }
}

.no-left-margin {
    margin-left: 0 !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.arrow {
    width: 8px;
    max-height: 13px;
    margin-left: 15px;
}

.lightBg {
    background: $lightBg;
}

.white {
    background: #fff;
}

.abBlueBack {
    background: $abBlue;
}

.white-text {
    color: #fff;
}

.cover {
    left: 0;
    width: 100%;
    //height: 30%;
    //min-height: 100px;
    //-webkit-clip-path: polygon(0 96%,100% 11%,100% 100%,0 100%);
    //clip-path: polygon(0 96%,100% 11%,100% 100%,0 100%);
    &.top {
        top: -2px;
    }

    &.bottom {
        bottom: -2px;
    }

    &.big2small {
        //-webkit-clip-path: polygon(0 0%,100% 0%,100% 11%,0% 100%);
        //clip-path: polygon(0 0%,100% 0%,100% 11%,0% 100%);
    }
}

.abBlue {
    background: $abBlue;
    overflow: hidden;

    h2,
    h3 {
        &.white {
            line-height: 1.1em;
        }
    }
}

.columns {
    &.half {
        @include grid-media($sixFifty) {
            @include grid-column(6);
        }
    }

    &.oneThree {
        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 30px;
        }
        @include grid-media($sixFifty) {
            @include grid-column(3);

            &-large {
                @include grid-column(9);
            }
        }
    }
}
@include grid-media($eightFifty) {
    .grid-collapse {
        @include grid-collapse;
    }

    .hide-desk {
        display: none !important;
        visibility: hidden;
        margin-right: 0 !important;
    }
}
// font classes

.left {
    text-align: left !important;
}

.center {
    text-align: center;
}

.upper {
    text-transform: uppercase;
}

.bigger {
    font-size: 18px;
}
// BIGGER MARGIN

h2 {
    letter-spacing: 0.47px;

    &.bigger-margin {
        margin-top: 65px;
    }
}
// layout

.image-bg {
    position: relative;
    background-size: cover !important;
    background-position: -200px 0 !important;
    @include grid-media($fiveHundo) {
        background-position: top left !important;
    }

    .wrap {
        padding-top: 100px;
        padding-bottom: 80px;
        max-width: 810px;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,80,158,.64);
        mix-blend-mode: multiply;
        // EDGE
        @supports (-ms-ime-align:auto) {
            background: rgba(13,65,115,0.73);
        }
    }

    p {
        text-align: center;
        font: 300 26px/1.1em $rockwell;
        color: #fff;
        @include grid-media($tablet) {
            font-size: 30px;
        }
    }
}

.text-align-right {
    float: right;
    position: relative;
    padding-right: 45px;
}

.border-left {
    border-left: 9px solid transparent;
    padding-left: 20px;
    margin-bottom: 45px;

    &.hdsBlueBorder {
        border-color: $hdsBlue;
    }

    &.gold {
        border-color: #EDC314;
    }

    &.hdsGreen {
        border-color: $hdsGreen;
    }
}

.center-it {
    text-align: center;
    clear: both;
    width: 100%;
    @include clearfix;

    &.til-tablet {
        @include grid-media($tablet) {
            text-align: left;
        }
    }

    .button {
        display: inline-block;
    }

    &.two {
        .button {
            //padding: 14px 19px;
            display: block;

            .arrow {
                margin-left: 5px;
            }
            @include grid-media($fiveFifty) {
                display: inline-block;

                &:first-of-type {
                    margin-right: 5px;
                }

                &:nth-of-type(2) {
                    margin-left: 5px;
                }
            }
        }

        &.equal {
            .button {
                display: inline-block;
                width: calc(50% - 15px);
            }
        }
    }
}

.lightBg {
    background: $lightBg;
    overflow: hidden;
}

.hdsBlue {
    color: $hdsBlue;
}
// WP

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.byline,
.updated:not(.published) {
    display: none;
}
@supports (-ms-ime-align:auto) {}