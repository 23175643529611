.button {
    font: 14px/1.2em $lato;
    font-weight: 500;
    color: $base;
    background: transparent;
    border-radius: 4px;
    text-align: center;
    margin: 15px 0 25px;
    padding: 11px 19px 14px;
    text-transform: uppercase;
    min-width: 200px;
    //display: block;
    .arrow {
        fill: #fff;
        transition: all 0.3s ease-in-out;
        position: relative;
        top: 1px;
    }

    &:hover {
        .arrow {
            transform: translateX(8px);
        }
    }

    &.ghost {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media all and (min-width:400px) {
        padding: 14px 25px 17px;

        &.ghost {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
    @include grid-media($fiveHundo) {
        padding: 14px 65px 17px;

        &.ghost {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &.ghost {
        border-width: 2px;
        border-style: solid;
    }

    &.white {
        border-color: #fff;
        color: #fff;

        &:hover {
            background: rgba(255,255,255,0.4);
        }
    }

    &.hdsBlueFill,
    &.hdsGreenFill {
        color: #fff;
    }

    &.hdsBlueFill {
        background: $hdsBlue;

        &:hover {
            background: shade($hdsBlue, 10%);
        }
    }

    &.hdsGreenFill {
        background: $hdsGreen;

        &:hover {
            background: shade($hdsGreen, 10%);
        }
    }

    &.hdsGreenBorder {
        border-color: $hdsGreen;

        &:hover {
            background: rgba($hdsGreen, 0.3);
        }
    }

    &.abBlueBorder {
        border-color: $abBlue;
        color: $abBlue;

        &:hover {
            background: $abBlue;
            color: #fff;
        }
    }

    &.hdsBlueBorder {
        border-color: $hdsBlue;
        color: $hdsBlue;

        &:hover {
            background: $hdsBlue;
            color: #fff;
        }
    }
}

#site-eyebrow {
    .button {
        text-transform: none;
        font-size: 14px;
        padding: 10px 17px;
        margin-left: 3px;
    }
}

.pdf {
    display: block;
    padding-top: 40px;
    border-top: 3px solid $abBlue;

    span {
        font-weight: 300;
        font-size: 29px;
        line-height: 26px;
        margin-left: 15px;
        display: inline-block;
        position: relative;
        top: -12px;
    }

    svg {
        width: 50px;
    }
}

.big-link {
    max-width: 420px;
    display: block;
    background: #fff;
    border-bottom: 7px solid $abBlue;
    color: $abBlue;
    font-size: 28px;
    font-family: $rockwell;
    font-weight: 300;
    line-height: 1.1em;
    text-align: left;
    position: relative;
    margin: 20px auto 40px;

    img {
        border-radius: 0;
        width: 100%;
        margin-bottom: -6px;
    }

    span {
        display: block;
        padding: 30px 20px;
    }

    &:hover {
        color: $hdsBlue;
        box-shadow: 0 5px 13px #a2a2a29e;
    }
    @include grid-media($fourHundo) {
        font-size: 30px;

        img {
            max-width: 175px;
            display: inline-block;
        }

        span {
            max-width: calc(100% - 220px);
            display: inline-block;
            position: absolute;
            //top: 13px;
            top: 50%;
            transform: translateY(-50%);
            left: 180px;
        }
    }
    @include grid-media($sixHundo) {
        font-size: 30px;
        margin-top: 40px;

        span {
            max-width: calc(100% - 140px);
            //top: 20px;
            //left: 180px;
        }
    }
    @include grid-media($sixFifty) {
        img {
            max-width: 135px;
        }

        span {
            max-width: calc(100% - 140px);
            //top: 0;
            left: 134px;
            font-size: 25px;
            line-height: 27px;
        }
    }
    @include grid-media($eightHundo) {
        img {
            max-width: 175px;
        }

        span {
            max-width: calc(100% - 140px);
            //top: 10px;
            left: 180px;
            font-size: 30px;
            line-height: 30px;
        }
    }
}
@include grid-media($sixFifty) {
    .big-link {
        &.left-big {
            margin: 20px 0 40px auto;
        }

        &.right-big {
            margin: 20px auto 40px 0;
        }
    }
}

.small-arrow {
    color: $gray;
    text-transform: uppercase;
    font-size: 10px;

    &:hover {
        color: $hdsGreen;
    }
}

a {
    &:not(.small-arrow) {
        .inline-small-arrow {
            fill: $hdsBlue;
            left: 0;
        }
    }

    &:hover {
        .inline-small-arrow {
            fill: $hdsGreen;
            left: 4px;
        }
    }
}