.icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    position: relative;
    top: -0.0625em;
    vertical-align: middle;
    width: 1em;
}

img {
    border-radius: 4px;

    &.aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &.alignleft {
        float: left;
        margin: 10px 10px 10px 0;
    }

    &.alignright {
        float: right;
        margin: 10px 0 10px 10px;
    }

    &.full-width.till-tablet {
        width: 100% !important;
        @include grid-media($tablet) {
            max-width: 660px;
            width: 90% !important;
        }
    }
}

.page-id-31 {
    .diagonal-break {
        //@include grid-media($sixFifty) {
        margin-top: -80px;
        //}
    }
}
@media screen and (min-width: 48em) {
    .menu-scroll-down .icon {
        height: 18px;
        width: 18px;
    }
}
@media screen and (min-width: 48em) {
    .site-header .menu-scroll-down .icon {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

img {
    max-width: 100%;
    height: auto;
    border: none;
}

.iframer {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.inline-small-arrow {
    width: 10px;
    height: 9px;
    margin-left: 10px;
    top: 1px;
    position: relative;
    transition: all 0.3s ease-in-out;
    fill: $gray;
}