#main {
    z-index: 1;
    position: relative;
}

.archive {
    .parallax::after {
        height: auto;
        top: 115px;
        width: auto;
        bottom: 0;
        right: 0;
    }
}

#billboard {
    background-size: cover !important;
    background-position: top center !important;
    position: relative;

    .__text {
        /*    position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;*/
    }

    .wrap {
        z-index: 6;
        margin-top: 20px;
        padding-top: 45px;
        padding-bottom: 75px;
        @include grid-media($oneK) {
            padding-top: 100px;
            padding-bottom: 150px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($hdsDarkGreen, .85);
        mix-blend-mode: multiply;
        @supports (-ms-ime-align:auto) {
            background: rgba(0,97,53,.7);
        }
    }

    &:after {
        content: '';
        height: 0;
        padding-top: 12.6%;
        position: absolute;
        z-index: 2;
        background-image: linear-gradient(-7deg,#FFF 0,#FFF 50%,transparent 50.1%,transparent 100%);
        bottom: -1px;
        left: 0;
        width: 100%;
    }

    .cover {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
    }

    h1 {
        font-size: 42px;
        line-height: 1.1em;
        font-weight: 900;
        letter-spacing: -0.34px;
        margin: 0 0 10px;

        &.entry-title {
            letter-spacing: -0.34px;
            font-size: 32px;
            @include grid-media($tablet) {
                font-size: 42px;
            }
        }
        @include grid-media($tablet) {
            max-width: 81%;
        }
    }

    h2 {
        letter-spacing: 0.5px;
        font-size: 17px;
        line-height: 18px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 0 0 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: #fff;
    }

    p {
        max-width: 640px;
    }
    // EDGE
    @supports (-ms-ime-align:auto) {
        .wrap {
            //top: calc(-75px + 50%);
        }
    }
}

.home {
    #billboard {
        height: auto;
        min-height: 500px;
        background-position: -125px top !important;
        @include grid-media($fiveFifty) {
            background-position: center top !important;
        }

        .wrap {
            padding-bottom: 60px;
            margin-bottom: 30px;
            padding-top: 125px;
            @include grid-media($eightFifty) {
                padding-top: 175px;
            }
        }
    }
}